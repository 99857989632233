<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 商戶權限列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-row class="add-btn">
            <el-col>
                <el-button type="primary"
                           @click="toPage('createCharacter')">新增商戶權限<i class="el-icon-lx-add el-icon--right"></i></el-button>
            </el-col>
        </el-row>
        <div class="container">
            <el-table :data="tableData"
                      border
                      class="table">
                <el-table-column prop="title"
                                 label="商戶ID"
                                 width="180"
                                 align="center" />
                <el-table-column prop="title"
                                 label="商戶名稱"
                                 width="180"
                                 align="center" />
                <el-table-column prop="title"
                                 label="帳號"
                                 width="180"
                                 align="center" />
                <el-table-column prop="auth"
                                 label="權限"
                                 align="center" />
                <el-table-column label="操作"
                                 align="center"
                                 width="180">
                    <template #default="scope">
                        <el-button size="mini"
                                   icon="el-icon-edit"
                                   @click="handleEdit('editCharacter', scope.row)">編輯</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { adminAuthList } from '../../../api/index'
export default {
    data() {
        return {
            tableData: [],
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            adminAuthList().then((res) => {
                if (res.code == 300) {
                    this.tableData = res.data.data_list
                    this.subStringLast()
                }
            })
        },
        toPage(page) {
            this.$router.push({ path: page })
        },
        //編輯
        handleEdit(page, info) {
            this.$router.push({
                path: page,
                query: { id: info.id },
            })
        },
        //字串去尾
        subStringLast() {
            this.tableData.forEach((element) => {
                element.auth = element.auth.substring(0, element.auth.length - 1)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.add-btn {
    padding-bottom: 15px;
}
.red {
    color: #ff0000;
}
</style>